import React from "react";
import Col from "react-bootstrap/Col";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const BlogCard = ({ title, image, uri, excerpt, imageAlt, boxShadow }) => {
	return (
		<Col className="mb-5 pb-xl-0" lg={6} xl={4}>
			<div
				style={{ boxShadow: boxShadow, borderRadius: "10px" }}
				className="w-100 h-100 position-relative feature-box "
			>
				<div
					className="w-100 h-100 bg-white"
					style={{ overflow: "hidden", borderRadius: "10px" }}
				>
					<Link className="" to={`/blog/${uri}`}>
						<GatsbyImage
							style={{ height: "280px" }}
							class="w-100 "
							image={image}
							alt={imageAlt}
						/>
					</Link>
					<div className="bg-white w-100 p-4">
						<Link className="text-decoration-none" to={`/blog/${uri}`}>
							<h3 className=" py-3">{title}</h3>
						</Link>
						<p>{excerpt}</p>
						<div style={{ height: "40px" }}></div>
						<Link
							className="red-link ps-4 py-4 position-absolute bottom-0 start-0"
							to={`/blog/${uri}`}
						>
							Read More
						</Link>
					</div>
				</div>
			</div>
		</Col>
	);
};

export default BlogCard;
