import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import SecondaryHero from "../../components/secondary-hero";
import BlogCard from "../../components/blog-card";

const BlogPage = ({ transitionStatus }) => {
	const data = useStaticQuery(graphql`
		query {
			heroImgMain: wpMediaItem(title: { eq: "Home Hero Main" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			blogs: allWpPost(
				filter: { title: { ne: "Website Images" } }
				sort: { fields: dateGmt, order: DESC }
			) {
				nodes {
					excerpt
					title
					slug
					blogFields {
						featuredImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 50
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "News",
				item: {
					url: `${siteUrl}/news`,
					id: `${siteUrl}/news`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Blog",
				item: {
					url: `${siteUrl}/news/blog`,
					id: `${siteUrl}/news/blog`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Blog | Friends of Eastbourne Hospital"
				description="Latest blog articles from Friends of Eastbourne Hospital"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/news/blog`,
					title: "Blog | Friends of Eastbourne Hospital",
					description:
						"Latest blog articles from Friends of Eastbourne Hospital.",
					images: [
						{
							url: `${data.heroImgMain?.localFile.publicURL}`,
							width: `${data.heroImgMain?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImgMain?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImgMain?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<SecondaryHero title="Latest blog articles" />
				</section>
				<section className="py-7">
					<Container>
						<Row className="justify-content-center">
							{" "}
							{data.blogs.nodes.map((post) => (
								<BlogCard
									boxShadow="0px 3px 50px #D1D1D1"
									title={post.title}
									excerpt={parse(post.excerpt)}
									uri={post.slug}
									image={
										post.blogFields.featuredImage?.localFile.childImageSharp
											.gatsbyImageData
									}
									imageAlt={post.blogFields.featuredImage.altText}
								/>
							))}
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default BlogPage;
